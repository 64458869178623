export const environment = {
  TYPE: "PROD",
  production: true,
  composerLogic: "https://platform.astrakode.tech/rc/projects",
  composerLogicLocal: "https://platform.astrakode.tech/rc/projects",
  externalService: "https://platform.astrakode.tech/external",
  externalServiceLocal: "http://platform.astrakode.tech/external",
  accessKeyId: "AKIAUEFRLLESGDZ7SI45",
  secretAccessKey: "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",
  region: "eu-central-1",
  cognito: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_uh1mJKLbT",
    aws_user_pools_web_client_id: "347q3rqt0r075gslcs9r3hvgdb",
    oauth: {
      domain: "astrakodeauth-prod.auth.eu-central-1.amazoncognito.com",
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://platform.astrakode.tech/",
      redirectSignOut: "https://platform.astrakode.tech/",
      responseType: "code",
    },
  },
  GEOLOCATION_API: "https://ipapi.co/json/",
  API_URL: "https://platform.astrakode.tech/AstraKodeWS/",
  HOME_URL: "https://platform.astrakode.tech/",
  TRACING_ORIGIN: ["https://platform.astrakode.tech"],
  SENTRY_DSN:
    "https://c14b37c3deb84845b08e30170048c8c1@o1069657.ingest.sentry.io/6064527",
  STRIPE_TOKEN:
    "pk_live_51JuFatERyYwgTtqEyusyorQRU7b1USX67sv50J1XshCA4TXAnW9smiF3lZSAhbSWzqB7ET9zWiuplcWCfgglZK2o00KPQkHLGE",
  LEAD_URL: "https://app.leadbi.com/api/v1/",
  LEAD_ID: "974fddf6-172f-467e-bc1b-5329926fae2b",
  LEAD_TOKEN:
    "06f752fe4bf7e3f236e230c41e41d4d9369d52cd38ab4eaba00a1f0e4a250fae3e97ca3176192e3bbd1f51ef8d37a6bebbdbf32d4a12375c589b8e495e968d98",
  LEAD_APP: 969,
  // CAPTCHA_TOKEN: "6Lf6-PcZAAAAAGgd27SOgJFlxf_mfzEssYfJ-4JC",
  CAPTCHA_SITEKEY: "6LeFQ5ImAAAAAH_h0YxnqQR9w6d3H6gfsiXBAYt-",
  NO_PAIR_TYPE: "NoPair",
  TRACES_SAMPLE_RATE: 0.5,
};
